export const dictionary = {
  Plain: {
    a: 'a',
    akesi: 'akesi',
    ala: 'ala',
    alasa: 'alasa',
    ale: 'ale',
    ali: 'ali',
    anpa: 'anpa',
    ante: 'ante',
    anu: 'anu',
    awen: 'awen',
    e: 'e',
    en: 'en',
    esun: 'esun',
    ijo: 'ijo',
    ike: 'ike',
    ilo: 'ilo',
    insa: 'insa',
    jaki: 'jaki',
    jan: 'jan',
    jelo: 'jelo',
    jo: 'jo',
    kala: 'kala',
    kalama: 'kalama',
    kama: 'kama',
    kasi: 'kasi',
    ken: 'ken',
    kepeken: 'kepeken',
    kili: 'kili',
    kin: 'kin',
    kipisi: 'kipisi',
    kiwen: 'kiwen',
    ko: 'ko',
    kon: 'kon',
    kule: 'kule',
    kulupu: 'kulupu',
    kute: 'kute',
    la: 'la',
    lape: 'lape',
    laso: 'laso',
    lawa: 'lawa',
    len: 'len',
    lete: 'lete',
    li: 'li',
    lili: 'lili',
    linja: 'linja',
    lipu: 'lipu',
    loje: 'loje',
    lon: 'lon',
    luka: 'luka',
    lukin: 'lukin',
    lupa: 'lupa',
    ma: 'ma',
    mama: 'mama',
    mani: 'mani',
    meli: 'meli',
    mi: 'mi',
    mije: 'mije',
    moku: 'moku',
    moli: 'moli',
    monsi: 'monsi',
    mu: 'mu',
    mun: 'mun',
    musi: 'musi',
    mute: 'mute',
    namako: 'namako',
    nanpa: 'nanpa',
    nasa: 'nasa',
    nasin: 'nasin',
    nena: 'nena',
    ni: 'ni',
    nimi: 'nimi',
    noka: 'noka',
    o: 'o',
    oko: 'oko',
    olin: 'olin',
    ona: 'ona',
    open: 'open',
    pakala: 'pakala',
    pali: 'pali',
    palisa: 'palisa',
    pan: 'pan',
    pana: 'pana',
    pata: 'pata',
    pi: 'pi',
    pilin: 'pilin',
    pimeja: 'pimeja',
    pini: 'pini',
    pipi: 'pipi',
    poka: 'poka',
    poki: 'poki',
    pona: 'pona',
    pu: 'pu',
    sama: 'sama',
    seli: 'seli',
    selo: 'selo',
    seme: 'seme',
    sewi: 'sewi',
    sijelo: 'sijelo',
    sike: 'sike',
    sin: 'sin',
    sina: 'sina',
    sinpin: 'sinpin',
    sitelen: 'sitelen',
    sona: 'sona',
    soweli: 'soweli',
    suli: 'suli',
    suno: 'suno',
    supa: 'supa',
    suwi: 'suwi',
    tan: 'tan',
    taso: 'taso',
    tawa: 'tawa',
    telo: 'telo',
    tenpo: 'tenpo',
    toki: 'toki',
    tomo: 'tomo',
    tu: 'tu',
    unpa: 'unpa',
    uta: 'uta',
    utala: 'utala',
    walo: 'walo',
    wan: 'wan',
    waso: 'waso',
    wawa: 'wawa',
    weka: 'weka',
    wile: 'wile',
  },
  SP: {
    a: '',
    akesi: '',
    ala: '',
    alasa: '',
    ale: '',
    ali: '',
    anpa: '',
    ante: '',
    anu: '',
    awen: '',
    e: '',
    en: '',
    esun: '',
    ijo: '',
    ike: '',
    ilo: '',
    insa: '',
    jaki: '',
    jan: '',
    jelo: '',
    jo: '',
    kala: '',
    kalama: '',
    kama: '',
    kasi: '',
    ken: '',
    kepeken: '',
    kili: '',
    kin: '',
    kipisi: '',
    kiwen: '',
    ko: '',
    kon: '',
    kule: '',
    kulupu: '',
    kute: '',
    la: '',
    lape: '',
    laso: '',
    lawa: '',
    len: '',
    lete: '',
    li: '',
    lili: '',
    linja: '',
    lipu: '',
    loje: '',
    lon: '',
    luka: '',
    lukin: '',
    lupa: '',
    ma: '',
    mama: '',
    mani: '',
    meli: '',
    mi: '',
    mije: '',
    moku: '',
    moli: '',
    monsi: '',
    mu: '',
    mun: '',
    musi: '',
    mute: '',
    namako: '',
    nanpa: '',
    nasa: '',
    nasin: '',
    nena: '',
    ni: '',
    nimi: '',
    noka: '',
    o: '',
    oko: '',
    olin: '',
    ona: '',
    open: '',
    pakala: '',
    pali: '',
    palisa: '',
    pan: '',
    pana: '',
    pata: '',
    pi: '',
    pilin: '',
    pimeja: '',
    pini: '',
    pipi: '',
    poka: '',
    poki: '',
    pona: '',
    pu: '',
    sama: '',
    seli: '',
    selo: '',
    seme: '',
    sewi: '',
    sijelo: '',
    sike: '',
    sin: '',
    sina: '',
    sinpin: '',
    sitelen: '',
    sona: '',
    soweli: '',
    suli: '',
    suno: '',
    supa: '',
    suwi: '',
    tan: '',
    taso: '',
    tawa: '',
    telo: '',
    tenpo: '',
    toki: '',
    tomo: '',
    tu: '',
    unpa: '',
    uta: '',
    utala: '',
    walo: '',
    wan: '',
    waso: '',
    wawa: '',
    weka: '',
    wile: '',
  },
  A: {
    a: 'ḽ',
    akesi: 'ੳ',
    ala: 'X',
    alasa: 'Ð',
    ale: '∞',
    ali: '∞',
    anpa: 'Џ',
    ante: '≍',
    anu: 'Y',
    awen: '⋏',
    e: '≫',
    en: '#ERROR!',
    esun: 'Ⴥ',
    ijo: 'O',
    ike: 'ᴖ',
    ilo: 'Ⴔ',
    insa: '⨃',
    jaki: '𝟞',
    jan: 'Ω',
    jelo: 'Å',
    jo: 'G',
    kala: 'ю',
    kalama: 'Ṻ',
    kama: 'Ꮑ',
    kasi: 'ȹ',
    ken: 'K',
    kepeken: 'ਨ',
    kili: '⥀',
    kin: '',
    kipisi: '',
    kiwen: '⛉',
    ko: '♧',
    kon: '⧚',
    kule: '🜁',
    kulupu: '🝆',
    kute: 'リ',
    la: ')',
    lape: '⊸',
    laso: 'Ϫ',
    lawa: 'θ',
    len: '𝈤',
    lete: 'Ӿ',
    li: '>',
    lili: 'ˇ',
    linja: '∽',
    lipu: '⌷',
    loje: 'Ă',
    lon: '∸',
    luka: 'ɲ',
    lukin: '⦿',
    lupa: 'U',
    ma: 'ⴲ',
    mama: 'Ǫ',
    mani: '℧',
    meli: '⍝',
    mi: 'P',
    mije: '⍜',
    moku: '⩆',
    moli: '🕀',
    monsi: '{',
    mu: 'ʘ',
    mun: '☽',
    musi: '☋',
    mute: 'ꔖ',
    namako: '',
    nanpa: '#',
    nasa: '5',
    nasin: '↑',
    nena: '⋂',
    ni: '↓',
    nimi: '▭',
    noka: 'Ь',
    o: '𝆹𝅥',
    oko: '',
    olin: 'ў',
    ona: 'ᓀ',
    open: '𝈣',
    pakala: '⍄',
    pali: '♬',
    palisa: '⫾',
    pan: '𝅬',
    pana: 'ἤ',
    pata: '',
    pi: 'L',
    pilin: '♡',
    pimeja: '⨻',
    pini: 'ꕯ',
    pipi: '‡',
    poka: '⎵',
    poki: '⨆',
    pona: 'ᵕ',
    pu: '⌻',
    sama: '#ERROR!',
    seli: 'ᗐ',
    selo: 'ℿ',
    seme: '?',
    sewi: 'ຝ',
    sijelo: 'm',
    sike: '⌾',
    sin: '﬩',
    sina: 'b',
    sinpin: '}',
    sitelen: 'ꘖ',
    sona: 'ṻ',
    soweli: '☟',
    suli: 'V',
    suno: '🝊',
    supa: 'ᚂ',
    suwi: 'Ṃ',
    tan: '⤺',
    taso: '┤',
    tawa: '𝈵',
    telo: '≈',
    tenpo: 'Ⓛ',
    toki: 'ö',
    tomo: '⌂',
    tu: '॥',
    unpa: 'ꕢ',
    uta: 'ụ',
    utala: '⤩',
    walo: 'ꕖ',
    wan: '1',
    waso: 'ᔨ',
    wawa: '∀',
    weka: '⸬',
    wile: 'ω',
  },
  B: {
    a: '⍤',
    akesi: '⍡',
    ala: '∅',
    alasa: '',
    ale: '◉',
    ali: '◉',
    anpa: '↓',
    ante: '⎌',
    anu: '∨',
    awen: '⚓',
    e: '↱',
    en: '&',
    esun: '',
    ijo: '⚛',
    ike: '☹',
    ilo: '✄',
    insa: '⎆',
    jaki: '☣',
    jan: 'ⵅ',
    jelo: '▥',
    jo: '⧈',
    kala: 'ᘙ',
    kalama: '♪',
    kama: '⧉',
    kasi: '⚘',
    ken: '✓',
    kepeken: '⚒',
    kili: 'ᴥ',
    kin: '◅',
    kipisi: '',
    kiwen: '●',
    ko: '◍',
    kon: '⚐',
    kule: '▧',
    kulupu: '፨',
    kute: '✆',
    la: '⊏',
    lape: '⌤',
    laso: '▦',
    lawa: '♕',
    len: '♟',
    lete: '☃',
    li: '↴',
    lili: '▵',
    linja: '☡',
    lipu: '‿',
    loje: '▤',
    lon: '⍾',
    luka: '⌈',
    lukin: '∢',
    lupa: '◘',
    ma: '⏚',
    mama: '☝',
    mani: '¤',
    meli: '♀',
    mi: '⇊',
    mije: '♂',
    moku: '☕',
    moli: '☠',
    monsi: '↫',
    mu: '⍣',
    mun: '☾',
    musi: '☊',
    mute: '⚃',
    namako: '',
    nanpa: '⋕',
    nasa: '⦼',
    nasin: 'ᔔ',
    nena: '♎',
    ni: '⇲',
    nimi: '⊟',
    noka: '⌊',
    o: '⚠',
    oko: '',
    olin: '♡',
    ona: '⇆',
    open: '⍽',
    pakala: '☁',
    pali: '♠',
    palisa: '∕',
    pan: '',
    pana: '✉',
    pata: '',
    pi: '⟄',
    pilin: '❦',
    pimeja: '■',
    pini: '⟟',
    pipi: '⩷',
    poka: '⫖',
    poki: '⚱',
    pona: '☺',
    pu: '',
    sama: '⊜',
    seli: '♨',
    selo: '⎋',
    seme: '⍰',
    sewi: '↑',
    sijelo: '♙',
    sike: '○',
    sin: '♲',
    sina: '⇈',
    sinpin: '⌸',
    sitelen: '✎',
    sona: '◬',
    soweli: 'ዥ',
    suli: '▽',
    suno: '☼',
    supa: '–',
    suwi: '⍨',
    tan: '↤',
    taso: '◦',
    tawa: '⇥',
    telo: '☔',
    tenpo: '⌛',
    toki: 'ᑈ',
    tomo: '⌂',
    tu: '⚁',
    unpa: '♋',
    uta: '⍥',
    utala: '⚔',
    walo: '☐',
    wan: '⚀',
    waso: '⍢',
    wawa: '↯',
    weka: '⤣',
    wile: '❣',
  },
  U6: {
    a: '😅',
    akesi: '🐊',
    ala: '🙅',
    alasa: '🎑',
    ale: '🌏',
    ali: '🌏',
    anpa: '🌰',
    ante: '💢',
    anu: '🎏',
    awen: '🌅',
    e: '🔸',
    en: '🍒',
    esun: '👛',
    ijo: '🐚',
    ike: '😱',
    ilo: '📎',
    insa: '🌀',
    jaki: '💩',
    jan: '👶',
    jelo: '🌱',
    jo: '🍯',
    kala: '🐟',
    kalama: '🎶',
    kama: '🐴',
    kasi: '🌳',
    ken: '📥',
    kepeken: '🌂',
    kili: '🍍',
    kin: '➰',
    kipisi: '✂️',
    kiwen: '💎',
    ko: '🍚',
    kon: '💨',
    kule: '🌈',
    kulupu: '🎎',
    kute: '👂',
    la: '🔹',
    lape: '💤',
    laso: '❇️',
    lawa: '👴',
    len: '👘',
    lete: '❄️',
    li: '🔺',
    lili: '🐜',
    linja: '〰️',
    lipu: '📁',
    loje: '🌹',
    lon: '🙇',
    luka: '👍',
    lukin: '👓',
    lupa: '⭕️',
    ma: '🌄',
    mama: '👪',
    mani: '💰',
    meli: '👧',
    mi: '👈',
    mije: '👨',
    moku: '🍲',
    moli: '💀',
    monsi: '👎',
    mu: '😻',
    mun: '🌜',
    musi: '💖',
    mute: '🌆',
    namako: '🍛',
    nanpa: '✌️',
    nasa: '😜',
    nasin: '🚞',
    nena: '🗻',
    ni: '🙌',
    nimi: '🎴',
    noka: '👞',
    o: '👋',
    oko: '👀',
    olin: '💑',
    ona: '👥',
    open: '🔦',
    pakala: '👹',
    pali: '🚴',
    palisa: '🎋',
    pan: '🍞',
    pana: '📤',
    pata: '👫',
    pi: '🎒',
    pilin: '💗',
    pimeja: '🌚',
    pini: '🌽',
    pipi: '🐞',
    poka: '👭',
    poki: '🍵',
    pona: '😄',
    pu: '',
    sama: '👯',
    seli: '🔥',
    selo: '🍊',
    seme: '👐',
    sewi: '💫',
    sijelo: '👕',
    sike: '🔃',
    sin: '🌲',
    sina: '👉',
    sinpin: '👚',
    sitelen: '⛲️',
    sona: '🐲',
    soweli: '🐒',
    suli: '☝️',
    suno: '🌞',
    supa: '📐',
    suwi: '🍭',
    tan: '🚢',
    taso: '🗿',
    tawa: '🏃',
    telo: '🌊',
    tenpo: '⌛️',
    toki: '💬',
    tomo: '🏡',
    tu: '🍂',
    unpa: '🍆',
    uta: '👄',
    utala: '✊',
    walo: '🌝',
    wan: '🍁',
    waso: '🐦',
    wawa: '⚡️',
    weka: '🎐',
    wile: '🐺',
  },
  Japanese: {
    a: 'あ',
    akesi: '獣',
    ala: '無',
    alasa: '探',
    ale: '全',
    ali: '全',
    anpa: '下',
    ante: '変',
    anu: 'ぬ',
    awen: '待',
    e: 'え',
    en: 'ん',
    esun: '市',
    ijo: '物',
    ike: '悪',
    ilo: '具',
    insa: '内',
    jaki: '汚',
    jan: '人',
    jelo: '黄',
    jo: '有',
    kala: '魚',
    kalama: '音',
    kama: '来',
    kasi: '木',
    ken: '能',
    kepeken: '使',
    kili: '果',
    kin: '又',
    kipisi: '切',
    kiwen: '石',
    ko: '粉',
    kon: '空',
    kule: '色',
    kulupu: '群',
    kute: '聞',
    la: 'ら',
    lape: '眠',
    laso: '青',
    lawa: '首',
    len: '布',
    lete: '冷',
    li: 'り',
    lili: '小',
    linja: '糸',
    lipu: '葉',
    loje: '赤',
    lon: '在',
    luka: '手',
    lukin: '見',
    lupa: '穴',
    ma: '土',
    mama: '母',
    mani: '貝',
    meli: '女',
    mi: '私',
    mije: '男',
    moku: '食',
    moli: '死',
    monsi: '後',
    mu: 'む',
    mun: '月',
    musi: '楽',
    mute: '多',
    namako: '冗',
    nanpa: '番',
    nasa: '狂',
    nasin: '道',
    nena: '丘',
    ni: '此',
    nimi: '称',
    noka: '足',
    o: 'お',
    oko: '目',
    olin: '愛',
    ona: '彼',
    open: '開',
    pakala: '打',
    pali: '作',
    palisa: '棒',
    pan: '米',
    pana: '授',
    pata: '氏',
    pi: 'ぴ',
    pilin: '心',
    pimeja: '黒',
    pini: '終',
    pipi: '虫',
    poka: '側',
    poki: '箱',
    pona: '良',
    pu: '',
    sama: '同',
    seli: '火',
    selo: '皮',
    seme: '何',
    sewi: '上',
    sijelo: '体',
    sike: '丸',
    sin: '新',
    sina: '君',
    sinpin: '前',
    sitelen: '画',
    sona: '知',
    soweli: '猫',
    suli: '大',
    suno: '日',
    supa: '面',
    suwi: '甜',
    tan: '因',
    taso: '許',
    tawa: '去',
    telo: '水',
    tenpo: '時',
    toki: '言',
    tomo: '家',
    tu: '二',
    unpa: '盛',
    uta: '口',
    utala: '戦',
    walo: '白',
    wan: '一',
    waso: '鳥',
    wawa: '力',
    weka: '遥',
    wile: '要',
  },
  Kantai: {
    a: '啊',
    akesi: '龟',
    ala: '不',
    alasa: '',
    ale: '全',
    ali: '全',
    anpa: '下',
    ante: '变',
    anu: '或',
    awen: '守',
    e: '把',
    en: '又',
    esun: '',
    ijo: '事',
    ike: '歹',
    ilo: '匕',
    insa: '内',
    jaki: '污/汙',
    jan: '人',
    jelo: '黄',
    jo: '有',
    kala: '鱼',
    kalama: '音',
    kama: '到',
    kasi: '木',
    ken: '能',
    kepeken: '用',
    kili: '果',
    kin: '也',
    kipisi: '',
    kiwen: '石',
    ko: '膏',
    kon: '气',
    kule: '色',
    kulupu: '组',
    kute: '耳',
    la: '喇',
    lape: '觉',
    laso: '青',
    lawa: '首',
    len: '巾',
    lete: '冰',
    li: '哩',
    lili: '小',
    linja: '糸',
    lipu: '叶',
    loje: '红',
    lon: '在',
    luka: '手',
    lukin: '看/见',
    lupa: '孔',
    ma: '土',
    mama: '母',
    mani: '元/贝',
    meli: '女',
    mi: '我',
    mije: '男',
    moku: '菜',
    moli: '死',
    monsi: '后',
    mu: '吽',
    mun: '月',
    musi: '玩',
    mute: '大',
    namako: '',
    nanpa: '个',
    nasa: '怪',
    nasin: '道',
    nena: '山',
    ni: '这',
    nimi: '名',
    noka: '足',
    o: '令',
    oko: '目',
    olin: '爱',
    ona: '他',
    open: '开',
    pakala: '打',
    pali: '工',
    palisa: '支',
    pan: '米',
    pana: '给',
    pata: '',
    pi: '的',
    pilin: '想/心',
    pimeja: '黑',
    pini: '末',
    pipi: '虫',
    poka: '旁',
    poki: '包',
    pona: '好',
    pu: '',
    sama: '同',
    seli: '火',
    selo: '甲',
    seme: '什',
    sewi: '上',
    sijelo: '身',
    sike: '回',
    sin: '新',
    sina: '你',
    sinpin: '前',
    sitelen: '画',
    sona: '知',
    soweli: '马/牛',
    suli: '高',
    suno: '日/光',
    supa: '张',
    suwi: '甜',
    tan: '从',
    taso: '只',
    tawa: '去',
    telo: '水',
    tenpo: '时',
    toki: '言',
    tomo: '穴',
    tu: '二',
    unpa: '性',
    uta: '口',
    utala: '战/斗',
    walo: '白',
    wan: '一',
    waso: '鸟',
    wawa: '力',
    weka: '脱',
    wile: '要',
  },
  Mean_JA: {
    a: '[間]ああ',
    akesi: '爬虫類/両生類',
    ala: '否定/ない',
    alasa: '集める/狩る',
    ale: 'すべて',
    ali: 'すべて',
    anpa: '下/低い',
    ante: '違う/変える',
    anu: '[接]または',
    awen: '留まる/待つ/保つ',
    e: '[分]目的語を導入',
    en: '[接]～と',
    esun: '市場/店',
    ijo: 'こと/もの',
    ike: '悪い',
    ilo: '道具/機械',
    insa: '内部',
    jaki: '汚い/ゴミ',
    jan: '人/誰か',
    jelo: '黄色',
    jo: '持つ/含む',
    kala: '魚/海の生物',
    kalama: '音/騒音/鳴る',
    kama: '来る/出来事',
    kasi: '植物/葉/草/木',
    ken: '可能/できる',
    kepeken: '使う/[前]～を使って',
    kili: '果物/野菜/キノコ',
    kin: '～も/～でさえ（強調）',
    kipisi: '切る',
    kiwen: '硬い/石/金属',
    ko: 'ジェル状の物/粉/ガム',
    kon: '空気/風/魂',
    kule: '色',
    kulupu: '集団/社会/人々',
    kute: '聞く',
    la: '[分]主節を導入',
    lape: '睡眠/休息',
    laso: '青/青緑',
    lawa: '頭/心/導く',
    len: '衣類/布',
    lete: '冷たい',
    li: '[分]3人称の述語を導入',
    lili: '小さい/少ない/短い',
    linja: '糸状の物/糸/髪/鎖',
    lipu: '平らで曲がる物/紙/本',
    loje: '赤',
    lon: 'いる/ある/[前]場所',
    luka: '手/腕',
    lukin: '見る/読む',
    lupa: '穴/窓/戸',
    ma: '土地/地球/国',
    mama: '親/母/父',
    mani: 'お金/富',
    meli: '女性',
    mi: '1人称代名詞（単数・複数）',
    mije: '男性',
    moku: '食事/食べる/飲む',
    moli: '死/死ぬ/殺す',
    monsi: '後ろ',
    mu: '[間]動物の鳴き声',
    mun: '月',
    musi: '娯楽/芸術/遊ぶ/楽しむ',
    mute: '多い/非常に/量',
    namako: '追加/スパイス',
    nanpa: '番号/序数',
    nasa: '愚かな/狂った/奇妙な',
    nasin: '道/方法',
    nena: '隆起物/鼻/丘/山',
    ni: 'これ/あれ',
    nimi: '単語/名前',
    noka: '足',
    o: '[分]～よ/命令/[間]やあ',
    oko: '目',
    olin: '愛',
    ona: '3人称代名詞（単数・複数）',
    open: '開く',
    pakala: '事故/失敗/破壊/壊す',
    pali: '活動/行為/する/作る',
    palisa: '棒状の物',
    pan: '穀物',
    pana: '与える/置く/送る',
    pata: '兄弟',
    pi: '[分]～の',
    pilin: '感情/心/考える',
    pimeja: '黒/暗い/影',
    pini: '終了/完了/終わる',
    pipi: '虫',
    poka: '側面/隣/[前]～と',
    poki: '入れ物/箱/コップ',
    pona: '良い/単純/積極性/直す',
    pu: 'トキポナの公式解説書',
    sama: '同じ/類似/[前]～の様な',
    seli: '火/熱/熱い',
    selo: '外側/表面/皮膚',
    seme: '何（疑問詞）',
    sewi: '上/高い',
    sijelo: '身体',
    sike: '円/球/丸い',
    sin: '新しい',
    sina: '2人称代名詞（単数・複数）',
    sinpin: '前面/壁',
    sitelen: '絵画/画像/描く/書く',
    sona: '知識/理解/知る',
    soweli: '動物/陸生哺乳類',
    suli: '大きい/長い',
    suno: '太陽/光',
    supa: '水平面/机/椅子/床',
    suwi: '菓子/甘い',
    tan: '原因/[前]～のため',
    taso: 'ただ/～だけ/[接]しかし',
    tawa: '行く/移動/[前]～へ',
    telo: '水/液体',
    tenpo: '時間/期間',
    toki: '言語/会話/言う/話す',
    tomo: '建物/家/部屋',
    tu: '2/ふたつ',
    unpa: '性/性的な',
    uta: '口',
    utala: '争い/戦争/攻撃/打つ',
    walo: '白',
    wan: '1/ひとつ',
    waso: '鳥/翼のある動物',
    wawa: 'エネルギー/強さ/力',
    weka: '離れた/不在/捨てる',
    wile: '欲しい/必要/意志',
  },
  Mean_EN: {
    a: 'ah',
    akesi: 'reptile',
    ala: 'no',
    alasa: 'gather',
    ale: 'all',
    ali: 'all',
    anpa: 'bottom',
    ante: 'different',
    anu: 'or',
    awen: 'continue',
    e: '[object]',
    en: 'and',
    esun: 'shop',
    ijo: 'thing',
    ike: 'bad',
    ilo: 'tool',
    insa: 'inside',
    jaki: 'dirt',
    jan: 'person',
    jelo: 'yellow',
    jo: 'have',
    kala: 'fish',
    kalama: 'sound',
    kama: 'come',
    kasi: 'plant',
    ken: 'able',
    kepeken: 'using',
    kili: 'vegetable',
    kin: 'also',
    kipisi: 'cut',
    kiwen: 'solid',
    ko: 'semi-solid',
    kon: 'air',
    kule: 'color',
    kulupu: 'grpup',
    kute: 'kute',
    la: '[context]',
    lape: 'rest',
    laso: 'blue',
    lawa: 'head',
    len: 'fabric',
    lete: 'cold',
    li: '[verb]',
    lili: 'small',
    linja: 'string',
    lipu: 'paper',
    loje: 'red',
    lon: 'in/on/at',
    luka: 'hand',
    lukin: 'look',
    lupa: 'hole',
    ma: 'land',
    mama: 'parent',
    mani: 'wealth',
    meli: 'woman',
    mi: 'me',
    mije: 'man',
    moku: 'food',
    moli: 'death',
    monsi: 'back',
    mu: 'moo',
    mun: 'moon',
    musi: 'play',
    mute: 'many',
    namako: 'extra',
    nanpa: 'number',
    nasa: 'crazy',
    nasin: 'way',
    nena: 'bump',
    ni: 'this',
    nimi: 'word',
    noka: 'foot',
    o: '[imperative]',
    oko: 'eye',
    olin: 'love',
    ona: 'them',
    open: 'begin',
    pakala: 'break',
    pali: 'do',
    palisa: 'stick',
    pan: 'grain',
    pana: 'give',
    pata: 'sibling',
    pi: '[rebracket]',
    pilin: 'feel',
    pimeja: 'dark',
    pini: 'end',
    pipi: 'bug',
    poka: 'side',
    poki: 'container',
    pona: 'good',
    pu: 'interact-with-the-official-toki-pona-book',
    sama: 'same',
    seli: 'heat',
    selo: 'outside',
    seme: '[question]',
    sewi: 'high',
    sijelo: 'body',
    sike: 'circle',
    sin: 'new',
    sina: 'you',
    sinpin: 'front',
    sitelen: 'draw',
    sona: 'know',
    soweli: 'animal',
    suli: 'big',
    suno: 'sun',
    supa: 'table',
    suwi: 'sweet',
    tan: 'from',
    taso: 'only',
    tawa: 'go_to',
    telo: 'water',
    tenpo: 'time',
    toki: 'talk',
    tomo: 'room',
    tu: 'two',
    unpa: 'sex',
    uta: 'mouth',
    utala: 'fight',
    walo: 'white',
    wan: 'one',
    waso: 'bird',
    wawa: 'power',
    weka: 'away',
    wile: 'desire',
  },
}
